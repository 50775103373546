<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="tabToolbar" flat dense>
         <v-tabs>
          <v-tab to="/channels">Channels</v-tab>
          <v-tab to="/channels/tiers">Tiers</v-tab>
        </v-tabs>
      </v-toolbar>
      
      <v-card-text>
        <router-view />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {};
</script>